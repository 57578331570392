<template>
  <b-card class="login-body mb-5">
    <h3 class="text-center mt-4 mb-4">{{$t('ACCOUNT.LOGIN')}}</h3>
    <form class="login-form" v-on:submit.prevent="onSubmit">
      <b-alert
        :show="showNoPermissions"
        variant="danger"
        dismissible
        @dismissed="showNoPermissions = false"
        fade
        >{{$t('ACCOUNT.LACKS_ACCESS')}}</b-alert
      >
      <b-alert
        :show="showWarning"
        variant="danger"
        dismissible
        @dismissed="showWarning = false"
        fade
        >{{$t('ACCOUNT.INVALID_EMAIL_PASSWORD')}}</b-alert
      >
      <b-form-input type="email" v-model="form.email" class="mb-8" placeholder="E-post" />
      <b-form-input type="text" v-model="form.token" class="mb-10" :placeholder="$t('MEMBER.PASSWORD')" />
      <div class="d-flex align-items-center justify-content-between mb-8">
        <router-link to="/ml-forget" tag="a" class="line-link blue-link"
          >{{$t('MEMBER.FORGET_PASSWORD')}}</router-link
        >
        <b-button class="btn-login" variant="primary" type="submit">{{$t('ACCOUNT.LOGIN')}}</b-button>
      </div>
    </form>
  </b-card>
</template>

<style lang="scss" scoped>
.login-body {
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
}
</style>

<script>
import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { mapState } from 'vuex';
import { LOGIN } from '@/core/services/store/auth.module';
import { SET_USER_ID, SET_COMPANY_ID } from '@/core/services/store/common.module';
import { SET_IS_SUPERADMIN, SET_IS_THS, LOGOUT } from '@/core/services/store/auth.module';

import axios from 'axios';

import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';
import store from '@/core/services/store';
import { hasAccessToPageForCurrentCompany, initFromLogin, getAnyPageWithAccess, clearCurrentCompanyId } from '@/core/services/companyAccess';

export default {
  mixins: [validationMixin],
  name: 'login-override',
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: '',
        token: ''
      },
      showWarning: false,
      showNoPermissions: false
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      token: {
        required,
        minLength: minLength(3)
      }
    }
  },
  mounted() {
    clearCurrentCompanyId();
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.showWarning = true;
        return;
      }

      const email = this.$v.form.email.$model;
      const token = this.$v.form.token.$model;

      // show loading
      const loader = this.$loading.show();

      try {
        const res = await axios.post('/user/login_override', { email, token });

        setKeyValue('user_login_email', email);

        if (res.status === 200) {
          // success
          if (res.data.selected_company_id) {
            this.$store.dispatch(SET_COMPANY_ID, res.data.selected_company_id);
          }

          this.$store.dispatch(LOGIN, {
            user: {
              user_id: res.data.id,
              email: email
            },
            token: res.data.token,
            refresh_token: res.data.refresh_token.refresh_token,
            expires_ts: res.data.refresh_token.expires_ts
          });
                    
          loader.hide();

          this.$router.push({ name: 'ml-auth-forward' });

        }
        else if (res.status === 403) {
          this.showNoPermissions = true;
          this.showWarning = false;
        }
        else if (res.status === 406) {
          this.showWarning = true;
          this.showNoPermissions = false;
        }

      }
      catch (err) {
        console.error(err);
        this.showWarning = true;
      }
      
      loader.hide();
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
